@import 'styles/layers.scss';

@layer component {
  .wrap {
    max-width: var(--max-width);
    width: 100%;
  }

  .wrap-content {
    padding-top: 25px;
  }

  .spacer {
    height: 5px;
    width: 100%;
  }
}
