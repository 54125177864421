@import 'styles/layers.scss';

@layer component {
  .dropdown-toggle {
    padding: 0;
    width: 30px;
    height: 30px;
    background-color: transparent !important;
    margin-right: 6px;
  }
  
  .dropdown-menu {
    width: 166px;
    padding: 0;
    border-radius: 6px;
    transform: translateX(-135px);
    &-direction {
      transform: translate(-135px, -205px);
    }
  }

  .dropdown-menu-item {
    height: 44px;
    padding-left: 11px;
    &:hover:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:hover:last-child{
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
